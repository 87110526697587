/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import PropTypes from 'prop-types';
import React from 'react';
import CookieConsent from 'react-cookie-consent';

import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import Newsletter from 'components/Newsletter/Newsletter';
import PromoSectionCentered from 'components/PromoSection/PromoSectionCentered';

import { site, fullWidthContent, siteContent } from './Layout.module.scss';
import { primary } from 'styles/components/buttons.module.scss';

const barStyle = {
  alignItems: 'center',
  background: '#fff',
  color: ' #757575',
  padding: '1rem 1.5rem',
  boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
};
const contentStyle = {
  maxWidth: '100%',
};
const pStyle = {
  marginBottom: '0',
  fontSize: '0.875rem',
};

const Layout = ({ children, fullWidth = false }) => {
  const location = useLocation();
  const data = useStaticQuery(graphql`
    query SiteQuery {
      site {
        siteMetadata {
          title
          brand
          site
        }
      }
    }
  `);

  return (
    <div className={site}>
      <Header siteBrand={data.site.siteMetadata.brand} />
      <main className={fullWidth ? fullWidthContent : siteContent}>
        {children}
      </main>
      <PromoSectionCentered>
        <Newsletter />
      </PromoSectionCentered>
      <CookieConsent
        buttonText="ΑΠΟΔΕΧΟΜΑΙ"
        cookieName="gdpr-acceptance"
        style={barStyle}
        disableButtonStyles={true}
        buttonClasses={primary}
        contentStyle={contentStyle}
        onAccept={() => {
          initializeAndTrack(location);
        }}
      >
        <p style={pStyle}>
          Η ιστοσελίδα <b>{data.site.siteMetadata.site}</b> χρησιμοποιεί cookies
          για να βελτιώνει διαρκώς την εμπειρία των χρηστών του.
        </p>
      </CookieConsent>
      <Footer site={data.site.siteMetadata.site} />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
