import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import { IconContext } from 'react-icons';
import { FiChevronDown, FiChevronRight, FiChevronUp } from 'react-icons/fi';

import Hamburger from 'components/Hamburger/Hamburger';
import Fb from 'components/Icons/Fb';
import Insta from 'components/Icons/Insta';
import MenuCart from 'components/Menu/MenuCart';
import MenuUser from 'components/Menu/MenuUser';
import SearchWidget from 'components/Search/SearchWidget';

import isTouchDevice from 'utilities/isTouchDevice';

import useMenu from 'queries/menu';

import * as styles from './Menu.module.scss';
import { transparentBtn } from 'styles/components/buttons.module.scss';

const query = graphql`
  query SiteMetaMenu {
    site {
      siteMetadata {
        title
        fb
        instagram
      }
    }
  }
`;

const Menu = () => {
  const {
    site: { siteMetadata: info },
  } = useStaticQuery(query);
  const noTouch = useRef(typeof window !== 'undefined' && !isTouchDevice());
  const [isOpen, setIsOpen] = useState(false);
  const { menu, submenus } = useMenu();
  const [openIndex, setOpenIndex] = useState(null);
  useEffect(() => {
    isOpen
      ? document.documentElement.setAttribute('data-is-menu-open', 'true')
      : document.documentElement.removeAttribute('data-is-menu-open');
    return () => {
      document.documentElement.removeAttribute('data-is-menu-open');
    };
  }, [isOpen]);
  return (
    <nav data-no-touch={noTouch.current} className={styles.nav}>
      <ul className={`${styles.navList} ${isOpen && styles.open}`}>
        <li className={styles.menuContainer}>
          <Hamburger
            isOpen={isOpen}
            handleClick={() => {
              setIsOpen(!isOpen);
            }}
          />
        </li>
        {menu.map((item, index) => (
          <li key={item.strapiId} className={styles.listItem}>
            <Link to={`/${item.slug}`} className={styles.link}>
              {item.title}
              {submenus[item.strapiId] && (
                <button
                  type="button"
                  className={`${transparentBtn} ${styles.submenuIconToggle}`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setOpenIndex(openIndex === index ? null : index);
                  }}
                >
                  <IconContext.Provider value={{ color: '#fff' }}>
                    {index !== openIndex ? <FiChevronDown /> : <FiChevronUp />}
                  </IconContext.Provider>
                </button>
              )}
            </Link>
            {submenus[item.strapiId] && (
              <ul
                className={`${styles.submenu}${
                  openIndex === index ? ' ' + styles.submenuOpen : ''
                }`}
              >
                {submenus[item.strapiId].map((subitem) => (
                  <li key={subitem.strapiId} className={styles.submenuItem}>
                    <Link
                      to={`/${item.slug}/${subitem.slug}`}
                      className={styles.link}
                    >
                      <FiChevronRight className={styles.submenuIcon} />
                      {subitem.title}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
        <li className={`${styles.listItem} ${styles.hideDesktop}`}>
          <Link className={styles.link} to="/etaireia">
            Η ΕΤΑΙΡΕΙΑ
          </Link>
        </li>
        <li className={`${styles.listItem} ${styles.hideDesktop}`}>
          <Link className={styles.link} to="/epikoinonia">
            ΕΠΙΚΟΙΝΩΝΙΑ
          </Link>
        </li>
        <li
          className={`${styles.listItem} ${styles.socialContainer} ${styles.hideDesktop}`}
        >
          <a
            href={info.fb}
            target="_blank"
            rel="noreferrer"
            className={styles.logo}
            aria-label={`${info.title} facebook page`}
          >
            <Fb fill={'#fff'} />
          </a>
          <a
            href={info.instagram}
            target="_blank"
            rel="noreferrer"
            className={styles.logo}
            aria-label={`${info.title} instagram page`}
          >
            <Insta fill={'#fff'} />
          </a>
        </li>
      </ul>
      <ul className={styles.iconsList}>
        <li className={styles.icon}>
          <SearchWidget />
        </li>
        <li className={styles.icon}>
          <MenuUser />
        </li>
        <li className={styles.icon}>
          <MenuCart />
        </li>
      </ul>
      <Hamburger
        isOpen={isOpen}
        handleClick={() => {
          setIsOpen(!isOpen);
        }}
      />
    </nav>
  );
};

export default Menu;
