import React from 'react';
import { IconContext } from 'react-icons';
import { IoCartOutline } from 'react-icons/io5';

const iconSize = { size: '1.5rem' };

const CartIcon = (props) => {
  return (
    <IconContext.Provider value={{ ...iconSize, ...props }}>
      <IoCartOutline />
    </IconContext.Provider>
  );
};

export default CartIcon;
