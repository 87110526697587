import El from 'assets/components/El';
import En from 'assets/components/En';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import {
  languageContainer,
  separator,
  language,
} from './LanguageSwitch.module.scss';

const LanguageSwitch = () => {
  const {
    site: { siteMetadata: info },
  } = useStaticQuery(query);
  return (
    <div className={languageContainer}>
      <a className={language} href={`${info.baseUrl}/en`}>
        <En />
      </a>
      <span className={separator}></span>
      <span className={language}>
        <El />
      </span>
    </div>
  );
};

const query = graphql`
  query LanguageSwitch {
    site {
      siteMetadata {
        baseUrl
      }
    }
  }
`;

export default LanguageSwitch;
