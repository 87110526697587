import { Link } from 'gatsby';
import React, { useRef } from 'react';
import { IconContext } from 'react-icons';
import { FiTrash2 } from 'react-icons/fi';
import { MdClose } from 'react-icons/md';

import Circle from 'components/Circle/Circle';
import Image from 'components/Image';
import QuantityButton from 'components/QuantityButton/QuantityButton';

import { useCart } from 'hooks/useCart';
import useOutside from 'hooks/useOutside';

import fbqEvent from 'utilities/fbq';

import {
  overlay,
  open,
  container,
  headerContainer,
  titleContainer,
  closeBtn,
  li,
  link,
  productsContainer,
  contentContainer,
  content,
  figure,
  title,
  price,
  actionsContainer,
  sumContainer,
  pricingText,
  pricingAmount,
  pricingContainer,
  shippingContainer,
  sum,
  empty,
  btnCheckout,
} from './CartOverlay.module.scss';
import { btnLink } from 'styles/components/links.module.scss';

const circleStyle = {
  width: '1.75rem',
  height: '1.75rem',
  marginLeft: '0.5rem',
  background: '#000',
  color: '#fff',
};

const CartOverlay = ({ isOpen, close }) => {
  const { cart, totals, removeItem, adjustItem } = useCart();
  const wrapperRef = useRef();
  useOutside(wrapperRef, () => isOpen && close());

  const completeOrder = () => {
    fbqEvent('track', 'InitiateCheckout', {
      value: Number.parseFloat(totals.sum),
      currency: 'EUR',
      contents: cart.map((item) => ({
        id: item.product.sku || item.product.id,
        quantity: item.quantity,
      })),
    });
  };

  if (!cart || cart.length === 0) {
    return (
      <div className={`${overlay}${isOpen ? ' ' + open : ''}`}>
        <div ref={wrapperRef} className={container}>
          <div className={headerContainer}>
            <div className={titleContainer}>
              <span>ΚΑΛΑΘΙ</span>
              <Circle style={circleStyle}>0</Circle>
            </div>
            <button type="button" className={closeBtn} onClick={close}>
              <MdClose />
            </button>
          </div>
          <p className={empty}>Το καλάθι είναι άδειο.</p>
        </div>
      </div>
    );
  }

  return (
    <div className={`${overlay}${isOpen ? ' ' + open : ''}`}>
      <div ref={wrapperRef} className={container}>
        <div className={headerContainer}>
          <div className={titleContainer}>
            <span>ΚΑΛΑΘΙ</span>
            <Circle style={circleStyle}>{cart.length}</Circle>
          </div>
          <button type="button" className={closeBtn} onClick={close}>
            <MdClose />
          </button>
        </div>
        <ul className={productsContainer}>
          {cart.map((item) => (
            <li key={item.id} className={li}>
              <Link to={item.relativePath} className={link}>
                <figure className={figure}>
                  <Image
                    filename={item.product.images[0].hash}
                    alt={
                      item.product.images[0].alternativeText ||
                      item.product.title
                    }
                  />
                </figure>
              </Link>
              <div className={contentContainer}>
                <Link to={item.relativePath} className={link}>
                  <div className={content}>
                    <h4 className={title}>{item.product.title}</h4>
                    <p className={price}>
                      {((item.price * item.quantity) / 100).toFixed(2)} €
                    </p>
                  </div>
                </Link>
                <div className={actionsContainer}>
                  <QuantityButton
                    quantity={item.quantity}
                    add={() => {
                      item.quantity < 9 &&
                        adjustItem(item.id, item.quantity + 1);
                    }}
                    subtract={() => {
                      item.quantity > 1 &&
                        adjustItem(item.id, item.quantity - 1);
                    }}
                  />
                  <button
                    type="button"
                    className={closeBtn}
                    onClick={(ev) => {
                      ev.preventDefault();
                      removeItem(item);
                    }}
                  >
                    <IconContext.Provider
                      value={{ color: '#b1b1af', size: '1.125rem' }}
                    >
                      <FiTrash2 />
                    </IconContext.Provider>
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
        {totals && totals.sum && (
          <div className={sumContainer}>
            <>
              <h6 className={pricingContainer}>
                <span className={pricingText}>ΑΞΙΑ ΠΡΟΪΟΝΤΩΝ</span>{' '}
                <span className={pricingAmount}>{totals.amount} €</span>
              </h6>
              {totals.hasCashShippingFee && (
                <h6 className={pricingContainer}>
                  <span className={pricingText}>ΚΟΣΤΟΣ ΑΝΤΙΚΑΤΑΒΟΛΗΣ</span>{' '}
                  <span className={pricingAmount}>
                    +{totals.cashShippingFee} €
                  </span>
                </h6>
              )}
              <>
                <h6 className={pricingContainer}>
                  <span className={pricingText}>ΜΕΤΑΦΟΡΙΚΑ</span>{' '}
                  <span className={pricingAmount}>
                    +{totals.hasShippingFee ? totals.shippingFee : '0.00'} €
                  </span>
                </h6>
                <div className={shippingContainer}>
                  <b>ΔΩΡΕΑΝ ΜΕΤΑΦΟΡΙΚΑ</b> για αγορές άνω των{' '}
                  {totals.priceWithoutFee} €
                </div>
              </>
              <hr style={{ marginTop: '1rem' }} />
            </>
            <h6 className={`${pricingContainer} ${sum}`}>
              <span className={pricingText}>ΣΥΝΟΛΟ</span>{' '}
              <span className={pricingAmount}>{totals.sum} €</span>
            </h6>
            <Link
              to={'/checkout/'}
              onClick={completeOrder}
              className={`${btnLink} ${btnCheckout}`}
            >
              ΟΛΟΚΛΗΡΩΣΗ ΑΓΟΡΩΝ
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default CartOverlay;
