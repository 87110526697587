const fbqEvent = (type = 'track', action, options) => {
  if (typeof window !== 'undefined') {
    if (!window.fbq) {
      return;
    }
    if (options) {
      window.fbq(type, action, options);
    } else {
      window.fbq(type, action);
    }
  }
};

export default fbqEvent;
