import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Logo from 'components/Icons/Logo';
import Menu from 'components/Menu/Menu';

import { header, fullContainer, inner, logoHeader } from './Header.module.scss';

const Header = ({ siteBrand }) => (
  <header className={header}>
    <div className={fullContainer}>
      <div className={inner}>
        <h1 className={logoHeader}>
          <Link aria-label={siteBrand} to="/">
            <Logo fill="#fff" aria-label={siteBrand} />
          </Link>
        </h1>
        <Menu></Menu>
      </div>
    </div>
  </header>
);

Header.propTypes = {
  siteBrand: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
